import Swiper from 'swiper';

export default function () {
    if (document.querySelector('.gate-slider') === null) {
        return;
    }

    const swiper = new Swiper('.gate-slider .swiper', {
        spaceBetween: 30,
        slidesPerView: "auto"
    });

    $('.gate-slider .arrow-next').click(function () {
        swiper.slideNext();
    })

    $('.gate-slider .arrow-prev').click(function () {
        swiper.slidePrev();
    })

}