import Splide from '@splidejs/splide';

export default function () {
    if (document.querySelector('.image-slider') === null) {
        return;
    }

    let slider = new Splide('.image-slider .splide', {
        autoWidth: true,
        arrows: false,
        pagination: false,
        gap: 50,
        loop: true,
        autoplay: true,
        interval: 2000,
        type: 'loop',
        breakpoints: {
            1024: {
                gap: 40,
                autoWidth: false,
            },
            768: {
                gap: 30
            }
        }
    });
    slider.mount();

}