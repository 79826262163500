export default function () {
    $(function () {
        $('.header__menutrigger').click(function () {
            $('body').toggleClass('menu-open');
        });

        $('.footer .menu-container h2').click(function () {
            $(this).parent().parent().toggleClass('open');
        });

        $('.footer .address-container h2').click(function () {
            $(this).parent().toggleClass('open');
        });

        $('.sticky-header-wrapper button').click(function () {
            $('body').toggleClass('overlay-menu-open');
            if ($('body').hasClass('overlay-menu-open')) {
                $('.sticky-header-wrapper button .text').text('Sluiten');
            } else {
                $('.sticky-header-wrapper button .text').text('Menu');
                // $(this).children('.text').text('Sluiten')
            }
        });

        $(window).resize(function() {
            if($(window).width() < 1025 && $('body').hasClass('overlay-menu-open')) {
                $('body').removeClass('overlay-menu-open')
            }
        });

        const options = {
            rootMargin: '0px 0px -100%',
            threshold: [0,0.1,0.2,0.5,1]
        }

        const observer = new IntersectionObserver(function (entries) {
            if(entries[0].isIntersecting && !$('body').hasClass('overlay-menu-open')) {
                $('.sticky-header-wrapper').addClass('hide');
                $('.sticky-header-wrapper').removeClass('show');

            } else {
                $('.sticky-header-wrapper').addClass('show');
                $('.sticky-header-wrapper').removeClass('hide');
            }

        }, options)
        observer.observe(document.querySelector('.header'));
    });
}