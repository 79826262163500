import Swiper, {Thumbs} from "swiper";
Swiper.use(Thumbs);

export default function () {
    if (document.querySelector('.configurator-layout') == null) {
        return;
    }

    $('.configurator-layout select').selectmenu();

    if ($(window).width() < 767) {
        $('.configurator-layout .container .options-container .option.colors label').each(function (index, ele) {
            if (index > 7) {
                $(this).hide();
            }
        });

        $('.configurator-layout .container .options-container .option.colors button.more-btn').click(function (e) {
            e.preventDefault();
            $(this).hide();
            $('.configurator-layout .container .options-container .option.colors label').show();
        });

        $('.view-configuration').click(function () {
            if ($('body').hasClass('view-config-info')) {
                $('body').toggleClass('view-config-info');
                $(this).text('Bekijk uw configuratie')
            } else {
                $('body').toggleClass('view-config-info');
                $(this).text('Sluiten')
            }
        });
    }

    $('.configurator-layout .container .options-container .option input:radio').change(function () {
        const val = $(this).val();
        let $grandParent = $(this).parent().parent();
        $grandParent.siblings('.chosen-value').children('span').text(val);

        if ($('.configurator-layout.maatwerk')) {
            let $parent = $grandParent.parent();
            let $tableRow = $('table .' + $parent.attr('data-table'));
            $tableRow.removeClass('empty');
            $tableRow.find('td:nth-of-type(2)').text(val);
        }
    });

    $('.configurator-layout .container .options-container .radio-buttons +  .option.selects select').on('selectmenuchange', function () {
        // debugger;
        let hoogte = $('.configurator-layout .container .options-container .radio-buttons +  .option.selects select[name="hoogte"]').val();
        let breedte = $('.configurator-layout .container .options-container .radio-buttons +  .option.selects select[name="breedte"]').val();
        let val = hoogte + 'cm x ' + breedte + 'cm'
        $('table .afmeting td:nth-of-type(2)').text(val);
    })

    $('.configurator-layout .container .options-container .option.checkboxes input:checkbox').change(function () {
        //Get all selected
        let $checked = $('.configurator-layout .container .options-container .option.checkboxes input:checkbox:checked');
        let $grandParent = $(this).parent().parent();
        let val = '';
        let i = 0;
        let length = $checked.length;

        $checked.each(function (index, ele) {
            val = val + $(ele).val()
            if (i < (length - 1)) {
                val = val + ', ';
            }
        });

        $grandParent.siblings('.chosen-value').children('span').text(val);

        if ($('.configurator-layout.maatwerk')) {
            let $parent = $grandParent.parent();
            let $tableRow = $('table .' + $parent.attr('data-table'));
            $tableRow.removeClass('empty');
            $tableRow.find('td:nth-of-type(2)').text(val);
        }
    });

    $('.own-color').change(function (e) {
        if ($('.configurator-layout .container .options-container .option input:radio:checked').val() === 'RAL Kleur') {
            $('table .kleur td:nth-of-type(2)').text('RAL ' + e.target.value);
        }
    })

    $('.configurator-layout .container .options-container .option.colors .custom-color input').change(function () {
        if (this.checked) {
            $('.own-color').show();
        } else {
            $('.own-color').hide();
        }
    });

    $('.configurator-layout .container .options-container .option.colors label:not(.custom-color) input').change(function () {
        if ($('.own-color').length > 0 && $('.own-color').is(':visible')) {
            $('.own-color').hide();
        }
    });


    const slideOne = new Swiper('.swiper-overview', {
        slidesPerView: 3,
        slidesPerGroup: 1,
        spaceBetween: 29,
        loop: false,
        // breakpoints: {
        //     767: {
        //         spaceBetween: 17
        //     }
        // }
    });

    const slidePreview = new Swiper('.swiper-preview', {
        slidesPerView: 1,
        loop: false,
        thumbs: {
            swiper: slideOne
        }
    })

    $('.exact').hide();
    $('.estimation').show();

    $(".configurator-layout.maatwerk .radio-buttons input").change(function() {
        if(this.checked) {
            if( $(this).val() == "Doe een schatting" ) {
                $('.estimation').show();
                $('.exact').hide();
            } else {
                $('.estimation').hide();
                $('.exact').show();
            }
        }
    });
}